import {
  Avatar,
  Box,
  Flex,
  HStack,
  Link,
  Stack,
  StackDivider,
  Text,
} from "@chakra-ui/react";
import {
  FiFileText,
  FiGrid,
  FiPieChart,
  FiSettings,
  FiUpload,
} from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import { DocumentCollapse } from "../Common/DocumentCollapse";
import { Logo } from "../Common/Logo";
import { QuickMenu } from "./QuickMenu";
import { SidebarButton } from "./SidebarButton";
import ProfilePicture from "../Common/ProfilePicture";
export const SideBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const email = localStorage.getItem("email");
  const full_name = localStorage.getItem("full_name") || "";
  const organization = localStorage.getItem("organization") || "";
  const handleNavClick = (path: string) => {
    navigate(path);
  };

  return (
    <Flex as="section" minH="100vh">
      <Stack
        flex="1"
        maxW={{ base: "full", sm: "xs" }}
        py={{ base: "6", sm: "8" }}
        px={{ base: "4", sm: "6" }}
        bg="bg.surface"
        borderRightWidth="1px"
        justifyContent="space-between"
      >
        <Stack spacing="8">
          <Logo alignSelf="center" />
          <Stack spacing="1">
            <SidebarButton
              leftIcon={<FiGrid />}
              isActive={location.pathname.toLowerCase() === "/home"}
              onClick={() => handleNavClick("/home")}
            >
              Overview
            </SidebarButton>
            <SidebarButton
              leftIcon={<FiPieChart />}
              isActive={location.pathname.toLowerCase() === "/analytics"}
              onClick={() => handleNavClick("/analytics")}
            >
              Analytics
            </SidebarButton>
            <DocumentCollapse />
            <SidebarButton
              leftIcon={<FiUpload />}
              isActive={location.pathname.toLowerCase() === "/upload"}
              onClick={() => handleNavClick("/upload")}
            >
              File Upload
            </SidebarButton>
          </Stack>
        </Stack>
        <Stack spacing="4" divider={<StackDivider />}>
          <Box />
          <Stack spacing="1">
            <SidebarButton leftIcon={<FiFileText />}>
              {/* The link below doesnt work, it should be a link to the documentation */}
              <Link href="https://docs.smartpipes.ai/" color="currentcolor">
                Docs
              </Link>
            </SidebarButton>
            <SidebarButton
              leftIcon={<FiSettings />}
              isActive={location.pathname.toLowerCase() === "/settings"}
              onClick={() => handleNavClick("/settings")}
            >
              Settings
            </SidebarButton>
          </Stack>
          <HStack spacing="3" justify="space-between">
            <HStack spacing="3">
              <ProfilePicture
                fullName={full_name}
                organization={organization}
              />

              <Box>
                <Text textStyle="sm" fontWeight="medium">
                  {full_name}
                </Text>
                <Text textStyle="sm" color="fg.muted">
                  {email}
                </Text>
              </Box>
            </HStack>

            <QuickMenu />
          </HStack>
        </Stack>
      </Stack>
    </Flex>
  );
};
