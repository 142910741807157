// AddressCard.tsx
import {
  Box,
  BoxProps,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useUpdateUser } from "../../hooks/useUpdateUser";

const organization = localStorage.getItem("organization") || "";

export const AddressCard = (props: BoxProps) => {
  const { updateUserInfo, loading, error } = useUpdateUser();

  // Initialize state with localStorage values or default values
  const [formData, setFormData] = useState({
    fullName: localStorage.getItem("full_name") || "",
    street: localStorage.getItem("billing_street_address") || "",
    city: localStorage.getItem("billing_city") || "",
    state: localStorage.getItem("billing_state") || "",
    zip: localStorage.getItem("billing_zip_code") || "",
    country: localStorage.getItem("billing_country") || "",
    phoneNumber: localStorage.getItem("phone_number") || "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [id]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const userData = {
      full_name: formData.fullName,
      billing_street_address: formData.street,
      billing_city: formData.city,
      billing_state: formData.state,
      billing_zip_code: formData.zip,
      billing_country: formData.country,
      phone_number: formData.phoneNumber,
    };

    await updateUserInfo(userData);
    localStorage.setItem("full_name", userData.full_name);
    localStorage.setItem("billing_street_address", userData.billing_street_address);
    localStorage.setItem("billing_city", userData.billing_city);
    localStorage.setItem("billing_state", userData.billing_state);
    localStorage.setItem("billing_zip_code", userData.billing_zip_code);
    localStorage.setItem("billing_country", userData.billing_country);
    localStorage.setItem("phone_number", userData.phone_number);
  };

  return (
    <Box
      as="form"
      bg="bg.surface"
      boxShadow="sm"
      borderRadius="lg"
      onSubmit={handleSubmit}
      {...props}
    >
      <Stack
        spacing="5"
        px={{ base: "4", md: "6" }}
        py={{ base: "5", md: "6" }}
      >
        <Stack spacing="6" direction={{ base: "column", md: "row" }}>
          <FormControl id="fullName">
            <FormLabel>Full Name</FormLabel>
            <Input value={formData.fullName} onChange={handleChange} />
          </FormControl>
          <FormControl id="organization">
            <FormLabel>Organization</FormLabel>
            <Input value={organization} isReadOnly />
          </FormControl>
          <FormControl id="phoneNumber">
            <FormLabel>Phone Number</FormLabel>
            <Input value={formData.phoneNumber} onChange={handleChange} />
          </FormControl>
        </Stack>
        <FormControl id="street">
          <FormLabel>Street</FormLabel>
          <Input value={formData.street} onChange={handleChange} />
        </FormControl>
        <Stack spacing="6" direction={{ base: "column", md: "row" }}>
          <FormControl id="city">
            <FormLabel>City</FormLabel>
            <Input value={formData.city} onChange={handleChange} />
          </FormControl>
          <FormControl id="state">
            <FormLabel>State / Province</FormLabel>
            <Input value={formData.state} onChange={handleChange} />
          </FormControl>
          <FormControl id="zip">
            <FormLabel>ZIP/ Postal Code</FormLabel>
            <Input value={formData.zip} onChange={handleChange} />
          </FormControl>
        </Stack>
        <FormControl id="country">
          <FormLabel>Country</FormLabel>
          <Input value={formData.country} onChange={handleChange} />
        </FormControl>
      </Stack>
      <Divider />
      <Flex direction="row-reverse" py="4" px={{ base: "4", md: "6" }}>
        <Button type="submit" isLoading={loading}>
          Save
        </Button>
      </Flex>
      {error && <Box color="red.500">{error}</Box>}
    </Box>
  );
};
