// For the brandColor, I used https://coolors.co/ to generate a color palette

export const brandColor = {
  50: "#FFC099",
  100: "#FFA770",
  200: "#FF9A5C",
  300: "#FF8E47",
  400: "#FF7D2C", // Pumpkin
  500: "#FF751F",
  600: "#FF680A",
  700: "#F55E00",
  800: "#E05600",
  900: "#CC4E00",
};

export const brandPurple = {
  50: "#9769F2",
  100: "#8956F0",
  200: "#7C43EF",
  300: "#6F31ED",
  400: "#5E17EB", // Electric Indigo
  500: "#5814E1",
  600: "#5112CE",
  700: "#4910BC",
  800: "#420fA9",
  900: "#3B0D96",
};
