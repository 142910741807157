import {
  Center,
  Container,
  Flex,
  Stack,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { CurrentSubscriptionCard } from "../components/Common/CurrentSubscriptionCard";
import { Profile } from "../components/Profile/Profile";
import { SideBar } from "../components/Sidebar/SideBar";
import { SourcesTable } from "../components/Settings/SourcesTable";

export const Settings = () => {
  return (
    <Flex direction="column" minH="100vh">
      <Flex flex="1">
        <SideBar />
        <Container py={{ base: "12", md: "16" }}>
          <Stack spacing="4">
            <Text alignSelf="end">Settings</Text>
            <Tabs size="md" variant="indicator">
              <TabList>
                <Tab>Account</Tab>
                <Tab>Integrations</Tab>
                <Tab>Billing</Tab>
              </TabList>
              <TabIndicator />
              <TabPanels>
                <TabPanel>
                  <Profile />
                </TabPanel>
                <TabPanel>
                  <SourcesTable />
                </TabPanel>
                <TabPanel>
                  <Center>
                    <CurrentSubscriptionCard />
                  </Center>
                </TabPanel>
                <TabPanel>
                  <p>Settings Content</p>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Stack>
        </Container>
      </Flex>
    </Flex>
  );
};
