import { Flex } from "@chakra-ui/react";
import IFrame from "react-iframe";
import { SideBar } from "../components/Sidebar/SideBar";
export const Home = () => {
  return (
    <Flex direction="column" minH="100vh">
      <Flex flex="1">
        <SideBar />
        <IFrame
          url="https://sp-manage-prod.4ftk1ume9gu76.us-east-1.cs.amazonlightsail.com/?user_id=nicolas66"
          width="100%"
          height="auto"
          id=""
          className=""
          display="block"
          position="relative"
        />
      </Flex>
    </Flex>
  );
};
