import {
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
} from "@chakra-ui/react";
import { HiOutlineChatBubbleLeftRight } from "react-icons/hi2";
import IFrame from "react-iframe";

export const PiperBot = () => {
  return (
    <Popover>
      <PopoverTrigger>
        <Button
          size="sm"
          variant="tertiary"
          justifyContent="end"
          iconSpacing="3"
          leftIcon={<HiOutlineChatBubbleLeftRight />}
          backgroundColor="#5E17EB"
          color="white"
          boxShadow="lg"
          _hover={{ backgroundColor: "#4a14b1" }}
          _focus={{ boxShadow: "outline" }}
        ></Button>
      </PopoverTrigger>
      <PopoverContent width="400px">
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>Piper</PopoverHeader>
        <PopoverBody>
          <IFrame
            url="https://sp-piper-prod.4ftk1ume9gu76.us-east-1.cs.amazonlightsail.com/?user_id=nicolas66"
            width="100%"
            height="500px"
            id=""
            className=""
            display="block"
            position="relative"
          />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
