export const sources = [
  {
    id: "1",
    name: "Production Database",
    handle: "postgres",
    status: "active",
    avatarUrl:
      "https://upload.wikimedia.org/wikipedia/commons/2/29/Postgresql_elephant.svg",
    lastSync: "today",
    currentCost: "$0.59",
  },
  {
    id: "2",
    name: "Data Warehouse",
    handle: "snowflake",
    avatarUrl:
      "https://logos-world.net/wp-content/uploads/2022/11/Snowflake-Symbol.png",
    lastSync: "today",
    status: "active",
    currentCost: "$7.68",
  },
  {
    id: "3",
    name: "Legacy Data Warehouse",
    handle: "bigquery",
    status: "disabled",
    avatarUrl:
      "https://cdn.icon-icons.com/icons2/2699/PNG/512/google_bigquery_logo_icon_168150.png",
    lastSync: "a month ago",
    currentCost: "$12.34",
  },
];
