import {
  Box,
  Container,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  SimpleGrid,
  Stack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { FiSearch } from "react-icons/fi";
import { PiperBot } from "../components/Piper/PiperBot";
import { SideBar } from "../components/Sidebar/SideBar";
import { SimpleAnalytics } from "../components/Sidebar/SimpleAnalytics";
import { pipeStats } from "../data/pipeStats";

export const Analytics = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Flex direction="column" minH="100vh">
      <Flex flex="1">
        <SideBar />
        <Container py={{ base: "4", md: "8" }} px={{ base: "0", md: 8 }}>
          <Box
            bg="bg.surface"
            boxShadow={{ base: "none", md: "sm" }}
            borderRadius={{ base: "none", md: "lg" }}
          >
            <Stack spacing="5">
              <Box px={{ base: "4", md: "6" }} pt="5">
                <Stack
                  direction={{ base: "column", md: "row" }}
                  justify="space-between"
                >
                  <Text textStyle="lg" fontWeight="medium">
                    Pipes Overview
                  </Text>
                  <InputGroup maxW="xs">
                    <InputLeftElement pointerEvents="none">
                      <Icon as={FiSearch} color="fg.muted" boxSize="5" />
                    </InputLeftElement>
                    <Input placeholder="Search" />
                  </InputGroup>
                </Stack>
              </Box>
              <Box overflowX="auto">
                <SimpleGrid
                  columns={{ base: 1, md: 3 }}
                  gap={{ base: "5", md: "6" }}
                >
                  {pipeStats.map((stat, id) => (
                    <SimpleAnalytics key={id} {...stat} />
                  ))}
                </SimpleGrid>
              </Box>
            </Stack>
          </Box>
        </Container>
      </Flex>
      <Box position="fixed" bottom="4" right="4">
        <PiperBot />
      </Box>
    </Flex>
  );
};
