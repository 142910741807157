import { chakra, HTMLChakraProps } from "@chakra-ui/react";

export const Logo = (props: HTMLChakraProps<"img">) => (
  <chakra.img
    src="./assets/smartpipes.png"
    alt="Logo"
    {...props}
    height="40"
    width="40"
  />
);
