// SecurityCard.tsx
import {
  Box,
  BoxProps,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useUpdateUser } from "../../hooks/useUpdateUser";

export const SecurityCard = (props: BoxProps) => {
  const { updateUserPassword, loading, error } = useUpdateUser();
  const [formData, setFormData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [id]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (formData.newPassword !== formData.confirmPassword) {
      alert("New password and confirm password do not match.");
      return;
    }

    const userData = {
      old_password: formData.oldPassword,
      password: formData.newPassword,
    };

    await updateUserPassword(userData);
  };

  return (
    <Box
      as="form"
      bg="bg.surface"
      boxShadow="sm"
      borderRadius="lg"
      {...props}
      onSubmit={handleSubmit}
    >
      <Stack
        spacing="5"
        px={{ base: "4", md: "6" }}
        py={{ base: "5", md: "6" }}
        direction="row"
      >
        <FormControl id="oldPassword">
          <FormLabel>Old Password</FormLabel>
          <Input
            type="password"
            value={formData.oldPassword}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl id="newPassword">
          <FormLabel>New Password</FormLabel>
          <Input
            type="password"
            value={formData.newPassword}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl id="confirmPassword">
          <FormLabel>Confirm New Password</FormLabel>
          <Input
            type="password"
            value={formData.confirmPassword}
            onChange={handleChange}
          />
        </FormControl>
      </Stack>
      <Divider />
      <Flex direction="row-reverse" py="4" px={{ base: "4", md: "6" }}>
        <Button type="submit" isLoading={loading}>
          Save
        </Button>
      </Flex>
      {error && <Box color="red.500">{error}</Box>}
    </Box>
  );
};
