import { Flex } from "@chakra-ui/react";
import { Dropzone } from "../components/Common/Dropzone";
import { SideBar } from "../components/Sidebar/SideBar";
import {
  Box,
  Container,
  FormControl,
  FormLabel,
  Center,
} from "@chakra-ui/react";
export const FileUpload = () => {
  return (
    <Flex direction="column" minH="100vh">
      <Flex flex="1">
        <SideBar />
        <Box as="section" bg="bg.white" py={{ base: "4", md: "8" }} flex="1">
          <Container maxW="lg">
            <Center>
              <FormControl id="file">
                <FormLabel textAlign="right">
                  Upload a file to smartpipes
                </FormLabel>
                <Dropzone />
              </FormControl>
            </Center>
          </Container>
        </Box>
      </Flex>
    </Flex>
  );
};
