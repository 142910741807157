import {
  Box,
  Button,
  Center,
  Container,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Link,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { Logo } from "../components/Common/Logo";
import { PasswordField } from "../components/Login/PasswordField";

import { useAuth } from "../hooks/useAuth";

export const Login = () => {
  const { login, error, loading } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const toast = useToast();

  const handleLogin = async () => {
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    if (!emailPattern.test(email)) {
      toast({
        title: "Invalid email format.",
        description: "Please enter a valid email address.",
        status: "error",
        duration: 9000,
        colorScheme: "brand",
        isClosable: true,
      });
      return;
    }

    await login(email, password);
  };

  return (
    <Container position="relative">
      <Center>
        <Logo />
      </Center>

      <Container
        maxW="lg"
        py={{ base: "0", sm: "8" }}
        px={{ base: "0", sm: "8" }}
      >
        <Stack spacing="8">
          <Stack spacing="6">
            <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
              <Heading size={{ base: "xs", md: "sm" }}>
                Log in to your account
              </Heading>
              <Text color="fg.muted">
                Don't have an account?{" "}
                <Link href="https://smartpipes.ai/contact-us/">Contact Us</Link>
              </Text>
            </Stack>
          </Stack>
          <Box
            py={{ base: "0", sm: "8" }}
            px={{ base: "4", sm: "10" }}
            bg={{ base: "transparent", sm: "bg.surface" }}
            boxShadow={{ base: "none", sm: "md" }}
            borderRadius={{ base: "none", sm: "xl" }}
          >
            <Stack spacing="6">
              <Stack spacing="5">
                <FormControl>
                  <FormLabel htmlFor="email">Email</FormLabel>
                  <Input
                    id="email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormControl>
                <PasswordField
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Stack>
              <HStack justify="space-between"></HStack>
              <Stack spacing="6">
                <Button onClick={handleLogin} disabled={loading}>
                  {loading ? "Logging in..." : "Sign in"}
                </Button>
                {error && <Text color="red.500">{error}</Text>}
                <HStack></HStack>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Container>
    </Container>
  );
};
