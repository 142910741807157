import { theme } from "@chakra-ui/pro-theme";
import { extendTheme } from "@chakra-ui/react";
import "@fontsource/poppins";
import { brandColor } from "./colors";
const proTheme = extendTheme(theme);

const extendedConfig = {
  fonts: { body: "Poppins", heading: "Poppins" },
  colors: { ...proTheme.colors, brand: brandColor },
};

export const myTheme = extendTheme(extendedConfig, proTheme);
