export const pipeStats = [
  {
    label: "Data moved",
    value: "2.87Gb",
    delta: { value: "1.5Mb", isUpwardsTrend: false },
  },
  {
    label: "Total Chat Bots",
    value: "2",
    delta: { value: "1", isUpwardsTrend: true },
  },
  {
    label: "Bots usage",
    value: "12.87%",
    delta: { value: "0.1%", isUpwardsTrend: true },
  },
];
