import {
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
  VStack,
} from "@chakra-ui/react";
import { FiMoreVertical } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

export const QuickMenu = () => {
  const navigate = useNavigate();

  const handleNavClick = (path: string) => {
    navigate(path);
  };

  const handleLogout = () => {
    // Remove the access_token from localStorage
    localStorage.removeItem("access_token");
    // Redirect to the login page or home page
    handleNavClick("/login");
  };

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<FiMoreVertical />}
        variant={"ghost"}
      />
      <MenuList>
        <MenuGroup title="Profile">
          <MenuItem onClick={() => handleNavClick("/settings")}>
            My Account
          </MenuItem>
          <MenuItem onClick={() => handleLogout()}>Log out</MenuItem>

          <MenuDivider />

          <Box bg="bg.surface" boxShadow="sm" borderRadius="lg" flex="1" p="4">
            <VStack align="start" spacing="4">
              <Text fontSize="md" color="gray.300">
                Organization: {localStorage.getItem("organization")}
              </Text>
              <Text fontSize="md" color="gray.300">
                App Version: 0.0.2
              </Text>
            </VStack>
          </Box>
        </MenuGroup>
      </MenuList>
    </Menu>
  );
};
