import { Box, Container, Stack, StackDivider, Text } from "@chakra-ui/react";
import { AddressCard } from "./AddressCard";
import { SecurityCard } from "./SecurityCard";

export const Profile = () => (
  <Container py={{ base: "4", md: "8" }}>
    <Stack spacing="5" divider={<StackDivider />}>
      <Stack
        direction={{ base: "column", lg: "row" }}
        spacing={{ base: "5", lg: "8" }}
        justify="space-between"
      >
        <Box flexShrink={0}>
          <Text textStyle="lg" fontWeight="medium">
            Your Profile
          </Text>
          <Text color="fg.muted" textStyle="sm">
            General info
          </Text>
        </Box>
        <AddressCard maxW={{ lg: "3xl" }} />
      </Stack>

      <Stack
        direction={{ base: "column", lg: "row" }}
        spacing={{ base: "5", lg: "8" }}
        justify="space-between"
      >
        <Box flexShrink={0}>
          <Text textStyle="lg" fontWeight="medium">
            Security
          </Text>
          <Text color="fg.muted" textStyle="sm">
            Change your password
          </Text>
        </Box>
        <SecurityCard maxW={{ lg: "3xl" }} />
      </Stack>
    </Stack>
  </Container>
);
