import { Link, Text, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../utils/constants";
export const useAuth = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const toast = useToast();

  const login = async (email: string, password: string) => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(`${API_URL}/api/v1/auth/token`, {
        method: "POST",
        headers: {
          accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          grant_type: "",
          username: email,
          password: password,
          scope: "",
          client_id: "",
          client_secret: "",
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      if (data.access_token) {
        const userResponse = await fetch(`${API_URL}/api/v1/user/me`, {
          method: "GET",
          headers: {
            accept: "application/json",
            token: data.access_token,
          },
        });
        const userData = await userResponse.json();
        localStorage.setItem("access_token", data.access_token);
        localStorage.setItem("email", userData.email);
        localStorage.setItem("full_name", userData.full_name);
        localStorage.setItem("organization", userData.organization);
        localStorage.setItem("billing_street_address", userData.billing_street_address);
        localStorage.setItem("billing_city", userData.billing_city);
        localStorage.setItem("billing_state", userData.billing_state);
        localStorage.setItem("billing_zip_code", userData.billing_zip_code);
        localStorage.setItem("billing_country", userData.billing_country);
        localStorage.setItem("phone_number", userData.phone_number);
        navigate("/Home");
      } else {
        throw new Error("Invalid token response");
      }
    } catch (error: any) {
      setError(error.message);
      toast({
        title: "Login failed.",
        description: (
          <Text>
            Please check your email and password. If you don't have an account,{" "}
            <Link
              href="https://smartpipes.ai/contact-us/"
              color="brand"
              textDecoration="underline"
            >
              contact us
            </Link>
            .
          </Text>
        ),
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return { login, error, loading };
};
