import React from "react";

interface ProfilePictureProps {
  fullName: string;
  organization: string;
}

const colorMap: { [key: string]: string } = {
  A: "#F0A500", // Example color mapping for different starting letters
  B: "#FF5733",
  C: "#C70039",
  D: "#900C3F",
  E: "#581845",
  F: "#FF00FF", // Fucsia
  G: "#FF5733",
  H: "#C70039",
  I: "#900C3F",
  J: "#581845",
  K: "#F0A500",
  L: "#FF5733",
  M: "#FF00FF", // Magenta
  N: "#C70039",
  O: "#900C3F",
  P: "#581845",
  Q: "#F0A500",
  R: "#FF5733",
  S: "#C70039",
  T: "#900C3F",
  U: "#581845",
  V: "#F0A500",
  W: "#FF5733",
  X: "#C70039",
  Y: "#900C3F",
  Z: "#581845",
};

const getInitials = (name: string) => {
  // if its a single word, return the first two letters
  if (name.split(" ").length === 1) {
    return name.slice(0, 2).toUpperCase();
  }
  // else return the first letter of each word
  const names = name.split(" ");
  const initials = names.map((n) => n[0].toUpperCase()).join("");
  return initials;
};

const getColor = (organization: string) => {
  const firstChar = organization[0].toUpperCase();
  return colorMap[firstChar] || "#000000"; // Default to black if no match found
};

const ProfilePicture: React.FC<ProfilePictureProps> = ({
  fullName,
  organization,
}) => {
  const initials = getInitials(fullName);
  const bgColor = getColor(organization);

  return (
    <div
      style={{
        width: "65px",
        height: "65px",
        borderRadius: "50%",
        backgroundColor: bgColor,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#FFFFFF",
        fontSize: "24px",
        fontWeight: "bold",
      }}
    >
      {initials}
    </div>
  );
};

export default ProfilePicture;
